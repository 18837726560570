import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { forgotPassword } from "@http/AuthRepository"
import { detectMob, useInput, isInputEmpty } from "@src/Helper"
import ForgotPasswordInputEmail from "./ForgotPasswordInputEmail"

const ForgotPassword = () => {
  // Hooks
  const emailRef = useRef(null)
  const email = useInput("")
  const error = useInput("")
  const isLoading = useInput(false)
  const isLinkSent = useInput(false)

  // Custom Functions
  const handleForgotPassword = () => {
    isLoading.setValue(true)
    forgotPassword(email.value)
      .then(() => {
        isLinkSent.setValue(true)
        isLoading.setValue(false)
        error.setValue("")
      })
      .catch(err => {
        error.setValue(err.response.data.errors.email)
        isLoading.setValue(false)
        isLinkSent.setValue(false)
      })
  }

  // Custom Component
  const LinkSent = () => {
    if (detectMob()) {
      return (
        <div id="m-linkSent" className="link-sent">
          <div className="container">
            <div className="row">
              <div className="flex-col col-12">
                <StaticImage
                  className="link-sent__icon"
                  src="../../../images/success-icon.svg"
                  alt="Link Sent"
                  width={58}
                  height={58}
                />
                <h1 className="link-sent__title heading-3">
                  E-mail telah terkirim
                </h1>
                <p className="link-sent__description body">
                  E-mail berisi tautan untuk mengganti kata sandi telah
                  dikirimkan ke <strong>{email.value}</strong>. Mohon untuk
                  mengeceknya sekarang.
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="link-sent">
          <div className="container pad-t-175">
            <div className="row">
              <div className="flex-col col-6 offset-3">
                <StaticImage
                  className="link-sent__icon"
                  src="../../../images/success-icon.svg"
                  alt="Link Sent"
                  width={73}
                  height={73}
                />
                <h1 className="link-sent__title heading-3">
                  E-mail telah terkirim
                </h1>
                <p className="link-sent__description body">
                  E-mail berisi tautan untuk mengganti kata sandi telah
                  dikirimkan ke <strong>{email.value}</strong>. Mohon untuk
                  mengeceknya sekarang.
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  // JSX
  if (detectMob()) {
    return isLinkSent.value ? (
      <LinkSent />
    ) : (
      <div id="m-forgotPassword" className="forgot-password">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="forgot-password__title heading-3">
                Lupa Kata Sandi
              </h1>
              <p className="forgot-password__description body">
                Masukkan alamat e-mail dan kami akan mengirimkan e-mail berisi
                tautan untuk mengganti kata sandi-mu.
              </p>
              <form
                className="forgot-password__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleForgotPassword()
                }}
              >
                <ForgotPasswordInputEmail
                  emailRef={emailRef}
                  email={email}
                  error={error}
                  isInputEmpty={isInputEmpty}
                />
                <button
                  type="submit"
                  className="forgot-password__form__button button button-md button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Kirim E-mail</>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return isLinkSent.value ? (
      <LinkSent />
    ) : (
      <div className="forgot-password">
        <div className="container pad-t-175">
          <div className="row">
            <div className="col-6 offset-3">
              <h1 className="forgot-password__title heading-3">
                Lupa Kata Sandi
              </h1>
              <p className="forgot-password__description body">
                Masukkan alamat email dan kami akan mengirimkan email berisi
                tautan untuk mengganti kata sandi-mu.
              </p>
              <form
                className="forgot-password__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleForgotPassword()
                }}
              >
                <ForgotPasswordInputEmail
                  emailRef={emailRef}
                  email={email}
                  error={error}
                  isInputEmpty={isInputEmpty}
                />
                <button
                  type="submit"
                  className="forgot-password__form__button button button-md button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Kirim E-mail</>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPassword
