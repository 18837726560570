import * as React from "react"
import ForgotPassword from "@components/Auth/ForgotPassword/ForgotPassword"
import Seo from "@components/seo"

const ForgotPasswordPage = () => (
  <>
    <Seo title="Forgot Password" />
    <ForgotPassword />
  </>
)

export default ForgotPasswordPage
