import React from "react"
import { detectMob } from "@src/Helper"

const ForgotPasswordInputEmail = ({ emailRef, email, error, isInputEmpty }) => {
  if (detectMob()) {
    return (
      <div className="forgot-password__form__email">
        <label
          htmlFor="inputEmail"
          className="forgot-password__form__email__label label-sm"
        >
          E-mail
        </label>
        <input
          type="text"
          className="forgot-password__form__email__input input-md"
          id="inputEmail"
          spellCheck={false}
          placeholder="Masukkan alamat e-mail kamu"
          ref={emailRef}
          onChange={e => {
            isInputEmpty(emailRef)
            email.setValue(e.target.value)
          }}
        />
        {error.value === "" ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value}</span>
        )}
      </div>
    )
  } else {
    return (
      <div className="forgot-password__form__email">
        <label
          htmlFor="inputEmail"
          className="forgot-password__form__email__label label-sm"
        >
          E-mail
        </label>
        <input
          type="text"
          className="forgot-password__form__email__input input-md"
          id="inputEmail"
          spellCheck={false}
          placeholder="Masukkan alamat e-mail kamu"
          ref={emailRef}
          onChange={e => {
            isInputEmpty(emailRef)
            email.setValue(e.target.value)
          }}
        />
        {error.value === "" ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value}</span>
        )}
      </div>
    )
  }
}

export default ForgotPasswordInputEmail
